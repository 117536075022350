<div class="account-pages">
  <div *ngIf="spinner.isLoading | async" class="loader-container">
    <mat-spinner class="load-spinner"></mat-spinner>
  </div>
</div>
<div class="container">
  
  <div class="loginsignup-form">
    <div class="login-logo"><img alt="atlas" src="assets/images/logobbb.png">
    </div>
    <h3 class="text-center"> Reset password </h3>

    <div class="clearfix"></div>
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <!-- <mat-label class="password-label">Password</mat-label>
        <input matInput name="password" type="password" formControlName="password"> -->
        <label class="label-email" for="email"><i class="fas fa-unlock-alt email-box"></i> Password</label>
        <input class="input-field form-control form-control-sm" id="email"
        [type]="hide ? 'password' : 'text'"  
        formControlName="password" >
          <span (click)="togglePassword()" class="material-icons visible-icon">{{hide ? 'visibility_off' : 'visibility'}}</span>
         <br>
                     <span
                     class="span-val"
                     *ngIf="password?.invalid && password?.touched"
                     >Password is required
                   </span><br>
         <!-- <mat-label class="password-label">Confirm Password</mat-label>
        <input matInput name="confirmPassword" type="password" formControlName="confirmPassword">
      -->
        <label class="label-email" for="email"><i class="fas fa-unlock-alt email-box"></i> Confirm Password</label>
        <input class="input-field form-control form-control-sm" id="email"
        [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword">
        <span (click)="toggleConfirmPassword()" class="material-icons visible-icon">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</span>
        <br>
                     <span
                     class="span-val"
                     *ngIf="confirmPassword?.invalid && confirmPassword?.touched"
                     >Confirm Password is required
                   </span><br>
     
        <div class="form-group">
        <a class="mt4 float-left" routerLink="/">Back to login</a>
        <input [disabled]="resetForm.invalid" (click)="onSubmit()" class="btn btn-primary float-right btn-block" type="button" value="Send">
        <div class="clearfix"></div>

      </div>
    </form>
  </div>
</div>