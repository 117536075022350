import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';
import { SpinnerService } from '../../services/spinner.service';
import { catchError, of } from 'rxjs';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit { 
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private router: Router,
    private authService: AuthServiceService,
    public spinner:SpinnerService) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
    }
  }
  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  hide: boolean = true;

  togglePassword() {
    this.hide = !this.hide;
  }


  public loginSubmit() {
    this.authService.login({
      email: this.loginForm.value.email || "",
      password: this.loginForm.value.password || ""
    })
    .subscribe({
        next : (data:any) => {
          this.authService.setToken(data.data.token);
          this.router.navigate(['/dashboard']);
        },
        error: (err) =>{
          customAlert('Invalid Credentials','warning');  
        }     
      })
    // .subscribe({
    //   next(data:any) {
    //     this.authService.setToken(data.data.token);
    //     this.router.navigate(['/dashboard']);
    //   },
    //   error(err) {
    //     customAlert('Email not Valid','warning');  
    //   }     
    // })

    
    // .subscribe(
    //   (data: any) => { 
    //     if(data.success){
    //      this.authService.setToken(data.data.token);
    //     this.router.navigate(['/dashboard']);
    //     } else {
    //       customAlert("Email or password is invalid",'warning');
    //     }
    //   });
  }

}
