<div class="container-fluid login-form-container">
  <div *ngIf="spinner.isLoading | async" class="loader-container">
    <mat-spinner class="load-spinner"></mat-spinner>
  </div>
  <div class="container">
    <div class="col-xl-5 col-lg-6 mx-auto login-container">
      <div class="row">
        <div class="col-lx-5">
          <div class="login-box">
            <div class="login-logo"><img alt="atlas" src="assets/images/logobbb.png">
            </div>
            <h3 class="text-center"> Welcome to <em>The BlackBook</em></h3>
            <form (ngSubmit)="loginSubmit()" [formGroup]="loginForm">

              <div class="login-row row no-margin">
                <label for="email"><i class="fas fa-envelope email-box"></i> Email Address</label>
                <input class="form-control form-control-sm" id="email" type="email" formControlName="email" placeholder="Email" required
                  email><br>
                <span class="span-val" *ngIf="email?.invalid && email?.touched">Email Address is required
                </span>
              </div>

              <div class="login-row row no-margin">
                <label for="password"><i class="fas fa-lock-alt email-box"></i> Password</label>
                <input class="form-control form-control-sm" id="password" [type]="hide ? 'password' : 'text'"
                  formControlName="password" placeholder="Password">
                <span (click)="togglePassword()" class="material-icons visible-icon">{{hide ? 'visibility_off' :
                  'visibility'}}</span>
                <br>
                <span class="span-val" *ngIf="password?.invalid && password?.touched">Password is required
                </span>
              </div>

              <div class="login-row row forrr no-margin">
                <!-- <p><input [(ngModel)]="payload.rememberMe" type="checkbox"> Remember Me</p> -->
                <p><a class="vgh" href="/forgot-password">Forgot Password?</a></p>
              </div>

              <div class="login-row btnroo row no-margin">
                <button [disabled]="loginForm.invalid" type="submit" class="btn btn-primary btn-block"> Sign
                  in</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>