import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpGeneralService } from '../../services/common/http-general.service';
import { UrlConstants } from '../../services/constants/api.route.constants';
import Swal from 'sweetalert2';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { customAlert } from 'src/assets/messages/messages';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  token = ''

  resetForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
      )
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
      )
    ])
  });
  get password() {
    return this.resetForm.get('password');
  }
  get confirmPassword() {
    return this.resetForm.get('confirmPassword');
  }
  hide: boolean = true;
  hideConfirmPassword: boolean = true;
  togglePassword() {
    this.hide = !this.hide;
  }
  toggleConfirmPassword() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
  constructor(private router: Router,
    private authService: AuthServiceService,public spinner:SpinnerService) {
    const url = this.router.url;
    this.token = url.split('reset-password/')[1];
  }

  public onSubmit(): void {
    if (this.resetForm.invalid) {
      return customAlert('password and confirmPassword is required !','warning');
    }

    const {
      password, confirmPassword
    } = this.resetForm.value

    if (password != confirmPassword) {
      return customAlert('password and confirmPassword are not equal !','warning');
    }

    if (!this.token) {
      return customAlert('Token is required!','warning');
    }

    this.authService.resetPassword(this.token, password || '').subscribe(
      (data: any) => {
        customAlert(data.message,'successfull');
        this.router.navigate(['/login']);
      });

  }



}