import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpRequest} from '@angular/common/http'
import { environment } from 'src/environments/environment';
//import { RequestOptions } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class HttpGeneralService {
  private url: string;
  
  constructor(public http: HttpClient) {
    this.url = environment.apiRoute;
  }

  post(apiRoute: string, body: any) {
    return this.http.post(`${this.url + apiRoute}`, body);
  }

  get(apiRoute: string, params:any) {
    return this.http.get(`${this.url + apiRoute}`,{params
      // ,headers: new HttpHeaders({
      //   Authorization: 'Bearer ' + localStorage.getItem('@$T0ken')
      // })
    }
    );
  }

  put(apiRoute: string, body: any) {
    return this.http.put(`${this.url + apiRoute}`, body);
  }

  delete(apiRoute: string, body:any) {
    const options = {
      body: body
    }
    return this.http.delete(`${this.url + apiRoute}`,options);
  }
 
 
}
