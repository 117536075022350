import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpGeneralService } from '../../services/common/http-general.service';
import { UrlConstants } from '../../services/constants/api.route.constants';
import Swal from 'sweetalert2';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { customAlert, messageAlert } from 'src/assets/messages/messages';
import { SpinnerService } from '../../services/spinner.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {

  forgotForm = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.email])
   });

  constructor(private router: Router, private httpService: HttpGeneralService,
  private authService: AuthServiceService,public spinner:SpinnerService){}

  get email() {
    return this.forgotForm.get('email');
  }

  public onSubmit(): void {
    if (!this.forgotForm.value.email) {
      return customAlert('Email is required !','warning');
    }
    this.httpService.post(UrlConstants.forgotPassword,this.forgotForm.value).subscribe(
        (data:any) => {
          customAlert('Forgot password link send to your email ','success');
        });
    
  } 
  
}