import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Backend returned code ${error.status}, ` + `body was:`, error.error);
          Swal.fire({
            position: 'top-end',
            html: `<div style="font-size: 16px;">${error.error.message}</div>`,
            timer: 2000,
            width: '300px',
            showConfirmButton: false,
          });
          return throwError(error);
        })
      );
  }
}
