import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from '../services/spinner.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(public spinner:SpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.isLoading.next(true);
  
    let setHeaders: any = {
      'Authorization': "Bearer "+localStorage.getItem('@$T0ken') || ''
    };
    if (!(request.body instanceof FormData)) {
      setHeaders = {...setHeaders, 'Content-Type': 'application/json'};
    }

    const newRequest = request.clone({setHeaders});

    return next.handle(newRequest).pipe(
      finalize(
        () => {
          this.spinner.isLoading.next(false);
        }
      )
    );
  }
}
