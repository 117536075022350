import Swal from "sweetalert2";

const message:any = {
  add: 'Added Successfully',
  update: 'Updated Successfully',
  delete: 'Deleted Successfully',
  select: 'Select Item',
  search: 'Wrong Search',
  input: 'Wrong Input',
  required: 'Required Value'
}


export const messageAlert = (key:string, icon:any) => {
  Swal.fire({
    toast: true,
    icon: icon,
    position: 'top-end',
    html: message[key],
    timer: 1500,
    timerProgressBar: false,
    width:"250px",
    showConfirmButton: false,
    allowOutsideClick: true
  })
}

export const customAlert = (key:string, icon:any) => {
  Swal.fire({
    toast: true,
    icon: icon,
    position: 'top-end',
    html: key,
    timer: 1500,
    timerProgressBar: false,
    width: '250px',
    showConfirmButton: false,
    allowOutsideClick: true
  })
}